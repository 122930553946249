/* ------------------------------ START GENERAL CSS ------------------------------ */

* {
    padding: 0;
    margin: 0;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.margin-auto {
    margin: auto
}

.inline-block-vmiddle {
    display: inline-block;
    vertical-align: middle;
}

.h-align-center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

a.text-link {
    text-decoration: none;
    color: #7f7f7f;
    cursor: pointer;
}

.text-link {
    margin-bottom: 5px;
    font-size: 10px;
    text-transform: uppercase;
    color: #7f7f7f;
    cursor: pointer;
}

.text-link:hover {
    color: #1d1d1d;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.padding10 {
    padding: 10px;
}

.padding20 {
    padding: 20px;
}

.width90 {
    width: calc(90% - 1px);
}

.width80 {
    width: calc(80% - 1px);
}

.width60 {
    width: calc(60% - 1px);
}

.width50 {
    width: calc(50% - 1px);
}

.width40 {
    width: calc(40% - 1px);
}

.width30 {
    width: 30%;
}

.width33 {
    width: 33%;
}

.width25 {
    width: 25%;
}

.width20 {
    width: calc(20% - 1px);
}

.width15 {
    width: calc(15% - 1px);
}

.width10 {
    width: calc(10% - 1px);
}

.width5 {
    width: calc(5% - 1px);
}

.width0 {
    width: 0 !important;
}

.backgroundWhite {
    background-color: #fff;
}

.backgroundGreen {
    background-color: #41e183;
}

.backgroundPurple {
    background-color: #7a80dc;
}

.backgroundRed {
    background-color: #fd5c7e;
}

.background-namaste {
    background-image: url('../images/namaste_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
}

.category-image {
    cursor: pointer;
    /* width: 100%; */
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 40% 50%;
    transform: translate(-50%, -50%);
}

.text-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.separator {
    width: 96%;
    height: 1px;
    margin: auto;
}

body {
    background: url('../images/namaste_space_bg.jpg') center repeat;
}

.section {
    margin: auto;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
}

.title {
    font-size: 30px;
    padding: 20px;
}

.subtitle {
    font-size: 22px;
    padding: 20px;
}

.plain-text {
    font-size: 13px
}

.link {
    color: rgb(22, 126, 187);
    font-weight: 600;
    cursor: pointer;
    font-size: 11px;
    padding-bottom: 10px
}

.blue-lite {
    color: #9cabd8
}

.namaste-label {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-bottom: 10px;
    color: #909090;
}

.namaste-label-site {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-bottom: 10px;
    color: #909090;
    font-size: 12px;
}

.namaste-text {
    font-size: 12px;
    color: black;
    padding-bottom: 10px;
}

.facebook-login {
    display: block;
    width: 90%;
    max-width: 432px;
    padding: 13px 15px;
    border-radius: 20px;
    margin: 0 auto 20px auto;
    border: 0px solid rgb(163, 163, 163);
    cursor: pointer;
    color: white;
    font-size: 13px;
    background-color: #3578E5;
}

.namaste-round-input {
    width: 80%;
    max-width: 400px;
    display: block;
    margin: auto;
    height: 30px;
    border-radius: 20px;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px 15px;
    margin-bottom: 20px;
}

.small-namaste-round-input {
    width: 80%;
    max-width: 400px;
    display: block;
    margin: auto;
    height: 30px;
    border-radius: 20px;
    border: 1px solid rgb(187, 187, 187);
    padding: 0px 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.namaste-round-textarea {
    width: 80%;
    max-width: 400px;
    display: block;
    margin: auto;
    border-radius: 20px;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px 15px;
    margin-bottom: 20px;
    height: 80px;
}

.chip {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 12px 6px 12px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: inline-block;
}

/* Suprascriere Material UI*/

.namaste-select-box {
    width: 80%;
    max-width: 432px;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 20px;
    display: block !important;
    margin: 0 auto 20px auto;
    padding: 5px 15px;
    font-size: 11px !important;
}

.checkSwitch .MuiIconButton-label {
    color: #41e183 !important;
}

.checkSwitch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #649a7b !important;
}

.uncheckSwitch .MuiIconButton-label {
    color: #fd5c7e !important;
}

/* Suprascriere Material UI*/

.namaste-select-box::before, .namaste-select-box::after {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.namaste-round-button {
    display: block;
    width: 90%;
    max-width: 432px;
    padding: 13px 15px;
    border-radius: 20px;
    margin: 0 auto 20px auto;
    border: 0px solid rgb(163, 163, 163);
    cursor: pointer;
    font-size: 13px;
}

.namaste-small-round-button {
    display: block;
    width: 80%;
    max-width: 432px;
    padding: 8px 15px;
    border-radius: 20px;
    margin: 0 auto 5px auto;
    border: 0px solid rgb(163, 163, 163);
    cursor: pointer;
    font-size: 12px;
    box-sizing: border-box;
}

.green-button {
    color: white;
    background: rgb(18, 204, 186);
}

input.small-namaste-round-input:focus, input.namaste-round-input:focus, button.namaste-small-round-button:focus, button.namaste-round-button:focus, textarea.namaste-round-textarea, .facebook-login:focus {
    outline-width: 0;
}

.opacity-title {
    font-size: 30px;
    font-weight: bold;
    opacity: 0.4;
    padding-bottom: 20px;
    display: inline-block;
}

.title1 {
    padding: 20px;
    font-size: 15px;
    text-align: center;
}

.title2 {
    padding: 15px;
}

.row-item {
    border-top: 1px solid rgb(184, 184, 184);
    padding: 5px;
    cursor: pointer;
}

.table-head {
    background-color: #525073;
    color: white;
    padding: 10px 5px;
}

.row-item:nth-child(odd) {
    background: rgb(247, 247, 247)
}

.row-item:hover {
    background: #eceff8;
}

.success-message {
    animation-name: menu;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    color: #02c753;
    position: relative;
    top: 0;
    font-size: 11px;
    margin-bottom: 5px;
}

.error-message {
    animation-name: menu;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    color: salmon;
    position: relative;
    top: 0;
    font-size: 11px;
    margin-bottom: 2px;
}

/* START CLOSE-X BUTTON */

.close-x-container {
    position: relative;
    height: 25px;
}

.close-x {
    cursor: pointer;
    height: 16px;
    position: absolute;
    top: 10px;
    right: 4px;
}

.close-x div {
    width: 20px;
    height: 1.5px;
    background: salmon;
}

.close-x div:first-child {
    transform: rotate(45deg);
    position: relative;
    top: 2px;
}

.close-x div:last-child {
    transform: rotate(-45deg);
}

/* END CLOSE-X BUTTON */

/* ------------------------------ END GENERAL CSS ------------------------------ */

/* ------------------------------ START HEADER ------------------------------ */

.header-container {
    padding-top: 10px;
}

header {
    width: 80%;
    margin: auto;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    min-height: 50px;
    position: relative;
}

.logo-container {
    width: calc(100% - 60px);
    text-align: center;
    color: white;
    margin-top: 10px;
    /* margin-bottom: 10px; */
}

.donate-header-container {
    width: 25px;
    height: 50px;
    /* background: green; */
}

.patreon-img {
    position: relative;
    top: 55%;
    left: 13px;
    transform: translateY(-50%);
    display: block;
}

/* ------------------------------ END HEADER ------------------------------ */

/* ------------------------------ START MENU ------------------------------ */

.menu-container {
    position: relative;
    cursor: pointer;
    width: 25px;
    margin-top: 17px;
    margin-right: 5px;
}

.hamburger-menu-bar {
    /* animation-name: menu; */
    height: 2px;
    width: 20px;
    background: #ffffff;
    margin-bottom: 4px;
}

.animation-hamburger-menu-top-bar {
    animation-name: topbar;
    animation-duration: 0.5s;
    transform: rotate(-45deg);
    position: relative;
    top: 6px;
}

.animation-hamburger-menu-bottom-bar {
    animation-name: bottombar;
    animation-duration: 0.5s;
    transform: rotate(45deg);
    position: relative;
    top: -6px;
}

.animation-hamburger-menu-middle-bar {
    animation-name: middlebar;
    animation-duration: 0.5s;
    opacity: 0;
}

@keyframes topbar {
    from {
        transform: rotate(0deg);
        top: 0px;
    }
    to {
        transform: rotate(-45deg);
        top: 6px;
    }
}

@keyframes middlebar {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes bottombar {
    from {
        transform: rotate(0deg);
        top: 0px;
    }
    to {
        transform: rotate(45deg);
        top: -6px;
    }
}

.menu-list {
    animation-name: menu;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    position: absolute;
    top: 0px;
    right: 30px;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    border-radius: 3px;
    min-width: 150px;
    z-index: 9;
    text-transform: uppercase;
}

.menu-item {
    text-align: left;
    color: #868686;
    font-size: 13px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.menu-item:hover {
    color: black;
}

.menu-list .menu-item:last-child {
    margin-bottom: 0px;
}

@keyframes menu {
    from {
        opacity: 0.6;
        top: -5px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}

/* ------------------------------ END MENU ------------------------------ */

/* ------------------------------ START LOGIN - REGISTER ------------------------------ */

.pop-up-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
}

.authentification-container {
    width: 90%;
    max-width: 500px;
    background: white;
    border-radius: 5px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sign-up-text {
    font-size: 11px;
    padding-bottom: 10px;
}

.sign-up-text span {
    color: rgb(22, 126, 187);
    font-weight: 600;
    cursor: pointer;
}

/* ------------------------------ END LOGIN - REGISTER ------------------------------ */

/* ------------------------------ SEARCH MEDITATIONS ------------------------------ */

.meditations-search {
    width: 80%;
    background-color: white;
    margin: 10px auto 0 auto;
    border-radius: 5px;
    height: 30px;
    position: relative;
}

.search-input {
    display: inline-block;
    width: calc(100% - 28px);
    margin-top: 2px;
}

.search-input input {
    width: 100%;
    outline: none;
    height: 22px;
    padding: 2px;
    border-width: 0;
    margin-left: 2px;
}

.search-icon {
    display: inline-block;
    margin-top: 3px;
}

/* ------------------------------ START MEDITATIONS ------------------------------ */

/* ------------------------------ START MEDITATIONS ------------------------------ */

.hide-element {
    display: none;
    animation: hide 0.5s;
}

.show-element {
    display: block;
    animation: show 0.5s;
}

.meditations-container {
    width: 80%;
    background-color: white;
    margin: 10px auto 0 auto;
    border-radius: 5px;
    position: relative;
    height: 70vh;
    padding: 2px;
    box-sizing: border-box;
}

.level-container {
    font-size: 12px;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    padding: 3px;
    margin: 3px 0;
    color: #3c3c3c;
    text-align: center;
}

.level-status-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
}

.meditation-bar {
    padding: 7px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 2px;
}

.meditation-bar-info {
    display: flex;
    justify-content: space-between;
}

.meditation-bar-info .meditation-title {
    text-align: left;
}

.meditation-bar-info .duration {
    text-align: right;
}

.meditation-image-container {
    /* width: 100%;  */
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.meditation-image {
    cursor: pointer;
    width: 100%;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1);
}

.meditation-image:hover {
    animation-name: zoomimg;
    animation-duration: 2s;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes zoomimg {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

.image-play-icon {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: white;
    opacity: 0.6
}

.meditation-info {
    padding: 5px;
    width: calc(100% - 150px);
    box-sizing: border-box;
}

.meditation-bar .meditation-title {
    font-size: 13px;
    margin-left: 4px;
    width: calc(100% - 40pX);
    display: inline-block;
    vertical-align: text-top;
    font-weight: bold;
}

.meditation-info .title {
    font-size: 14px;
    color: #60709e;
    font-weight: bold;
    margin-bottom: 4px;
}

.meditation-info .description {
    font-size: 12px;
    color: #3c3c3c;
    text-indent: 18px;
}

.mediation-tags {
    margin-top: 10px;
}

.mediation-tags .tag {
    font-size: 13px;
    margin-right: 5px;
    display: inline-block;
    color: #2d65a3;
    font-style: italic;
}

.checked-img {
    padding-top: 3px;
}

/* START MEDITATIONS DIVIDER ANIMATION */

.toogleCategory {
    border-radius: 5px;
    width: 25px;
    height: calc(70vh - 4px);
    /* background-color: #e06660; */
    position: absolute;
    cursor: pointer;
    /* animation-name: movingBar; */
}

.toogleCategoryArrow {
    position: relative;
    top: 50%;
    color: white;
    transform: translateY(-50%);
}

.toogleCategoryArrowRotate0 {
    transform: rotate(0deg);
    -moz-animation: rotateArrow0 1s;
    -webkit-animation: rotateArrow0 1s;
    animation: rotateArrow0 1s;
    animation-fill-mode: forwards;
}

.toogleCategoryArrowRotate180 {
    transform: rotate(180deg);
    -moz-animation: rotateArrow180 1s;
    -webkit-animation: rotateArrow1800 1s;
    animation: rotateArrow180 1s;
    animation-fill-mode: forwards;
}

@keyframes rotateArrow180 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes rotateArrow0 {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
}

.moveRight {
    -webkit-animation: moveright 1s;
    animation: moveright 1s;
    animation-fill-mode: forwards;
}

.moveLeft {
    -webkit-animation: moveleft 1s;
    animation: moveleft 1s;
    animation-fill-mode: forwards;
}

@keyframes moveright {
    0% {
        background-color: #33b4b1;
        /* left: 0;
        right: calc(100% - 25px); */
    }
    100% {
        background-color: #e07973;
        /* left: calc(100% - 25px);
        right: 0 */
    }
}

@keyframes moveleft {
    0% {
        background-color: #e07973;
        /* left: 95%;
        right: 0; */
    }
    100% {
        background-color: #33b4b1;
        /* left: 0;
        right: 95%; */
    }
}

.leftCategory {
    width: calc(100% - 27px);
    height: calc(70vh - 4px);
    overflow: auto;
    margin-right: 2px;
}

.rightCategory {
    overflow: auto;
    height: calc(70vh - 4px);
    width: calc(100% - 27px);
    right: 0;
    float: right;
    padding-left: 2px;
    box-sizing: border-box;
}

.grow {
    display: inline-block;
    -webkit-animation: grow 1s;
    animation: grow 1s;
    animation-fill-mode: forwards;
}

.shrink {
    display: none;
    -webkit-animation: shrink 1s;
    animation: shrink 1s;
    animation-fill-mode: forwards;
}

@keyframes grow {
    0% {
        display: 'none'
    }
    100% {
        display: 'inline-block';
    }
}

@keyframes shrink {
    0% {
        display: 'inline-block'
    }
    100% {
        display: 'none';
    }
}

/* TEEEEESTTT */

.mare {
    width: calc(100% - 27px);
    -webkit-animation: mare 1s;
    animation: mare 1s;
    animation-fill-mode: forwards;
}

.mic {
    width: 0%;
    -webkit-animation: mic 1s;
    animation: mic 1s;
    animation-fill-mode: forwards;
}

@keyframes mare {
    0% {
        width: 0
    }
    100% {
        width: calc(100% - 27px);
    }
}

@keyframes mic {
    0% {
        width: calc(100% - 27px);
    }
    100% {
        width: 0;
    }
}

/* END MEDITATIONS DIVIDER ANIMATION */

/* ------------------------------ END MEDITATIONS ------------------------------ */

/* ------------------------------ START ADMIN ------------------------------ */

.admin-container {
    background-color: #f9f9f9;
    /* min-height: 400px; */
    padding: 20px;
}

.admin-menu {
    text-align: center;
}

.admin-menu .admin-tab:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.admin-menu .admin-tab:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.admin-tab {
    border: 1px solid rgb(208, 208, 208);
    padding: 5px 10px;
    cursor: pointer;
    min-width: 100px;
    background: white;
}

.admin-tab p {
    color: #565656;
    font-size: 11px;
}

.admin-child-container {
    background-color: white;
    padding: 20px;
    font-size: 12px;
}

.image-thumb-admin-container {
    position: relative;
    width: 200px;
    margin: 20px auto;
}

.image-thumb-admin {
    width: 200px;
}

ul.draggableList {
    margin: 0;
    padding: 0
}

li.draggableItem {
    cursor: move;
    display: block;
    padding: 20px 10px;
    background: white;
    border-bottom: solid 1px gray;
}

/* ------------------------------ END ADMIN ------------------------------ */

/* ------------------------------ START PLAYER ------------------------------ */

.meditatii-player-container {
    padding-left: 0 !important;
}

#meditatii-player-volume {
    width: 36px;
    position: relative;
    height: 100%;
    margin-top: -10px;
    margin-left: 0;
    cursor: pointer
}

.meditatii-volume-container {
    width: 36px;
    margin-left: 10px;
    display: inline-flex;
    height: 36px;
}

.meditatii-volume-bars {
    width: 7px;
    position: absolute;
    background-color: #7a80dc;
}

/* ------------------------------ END PLAYER ------------------------------ */

/* ------------------------------ START USER PLAYER ------------------------------ */

.player {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 9;
}

.play-button {
    font-size: 25px;
    color: white;
    cursor: pointer;
}

.pause-button {
    font-size: 21px;
    color: white;
    cursor: pointer;
    padding: 2px;
}

.stop-button {
    font-size: 15px;
    color: white;
    cursor: pointer;
    padding: 3px;
}

.player-back, .player-forward {
    margin-top: 3px;
}

.back-forward-button {
    font-size: 15px;
    color: white;
    cursor: pointer;
    padding: 3px;
}

.remaining_time {
    text-align: center;
    font-size: 30px;
    font-weight: lighter;
    color: #738491;
    padding: 5px 0 0px 0;
}

.progressive-bar {
    height: 7px;
    width: 100%;
    background-color: #182027;
    cursor: pointer;
}

.progressive-bar-active {
    height: 7px;
    width: 0;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(153, 249, 159, 1) 0%, rgba(38, 161, 255, 1) 100%);
}

.player-controls {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(55, 70, 83, 1) 0%, rgba(72, 92, 110, 1) 100%);
}

.player-controls-buttons {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0;
}

.player-play {
    width: 25px;
    height: 25px;
    border: 1px solid pink;
    border-radius: 100%;
    position: relative;
    margin: 0 5px;
}

.player-pause {
    width: 25px;
    height: 25px;
    border: 1px solid #f35110;
    border-radius: 100%;
    position: relative;
    margin: 0 5px;
}

.player-stop {
    width: 20px;
    height: 20px;
    border: 1px solid red;
    border-radius: 100%;
    position: relative;
    top: 3px;
    margin: 0 5px;
}

.player-stop-disabled {
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 100%;
    position: relative;
    top: 3px;
    margin: 0 5px;
}

.close-player-container {
    position: absolute;
    right: 5px;
    top: 10px;
}

.close-player:hover {
    color: #f35110;
}

.close-player {
    font-size: 15px;
    color: #738491;
    cursor: pointer;
}

/* ------------------------------ END USER PLAYER ------------------------------ */

/* ------------------------------ START TAG ------------------------------ */

.tags-container {
    max-width: 800px;
    margin: auto;
}

.tag-item {
    background: #7a80dc;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 12px 6px 12px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

/* ------------------------------ END TAG ------------------------------ */

/* ------------------------------ START RATING ------------------------------ */

.rating-container {
    margin-bottom: 5px;
    ;
}

.stars-outer {
    display: inline-block;
    position: relative;
    font-family: "Font Awesome 5 Free";
    cursor: pointer
}

.stars-outer::before {
    font-size: 15px;
    font-family: "Font Awesome 5 Free";
    content: "\F005 \F005 \F005 \F005 \F005";
    letter-spacing: -1px;
    color: #909090;
}

.stars-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
}

.stars-inner::before {
    font-size: 15px;
    font-family: "Font Awesome 5 Free";
    content: "\F005 \F005 \F005 \F005 \F005";
    letter-spacing: -1px;
    color: #c3c3c3;
    font-weight: 700
}

.stars-outer-content {
    display: inline-block;
    position: relative;
    font-family: "Font Awesome 5 Free";
    cursor: pointer
}

.stars-outer-content::before {
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    content: "\F005 \F005 \F005 \F005 \F005";
    letter-spacing: -1px;
    color: #c3c3c3;
}

.stars-inner-content {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
}

.stars-inner-content::before {
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    content: "\F005 \F005 \F005 \F005 \F005";
    letter-spacing: -1px;
    color: #c3c3c3;
    font-weight: 700
}

/* ------------------------------ END RATING ------------------------------ */

/* ------------------------------ START FOOTER ------------------------------ */

.footer {
    width: 80%;
    background-color: white;
    margin: 10px auto 0 auto;
    border-radius: 5px;
}

.social-media {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 0;
}

.social-media img {
    padding: 5px;
    cursor: pointer;
    opacity: 0.6;
    transition: 1;
    transition-timing-function: ease;
}

.social-media img:hover {
    opacity: 1;
}

.links-group {
    padding: 20px;
}

.copyright {
    text-align: center;
    padding: 10px 0;
    font-size: 10px;
    color: #7F7F81;
}

/* ------------------------------ END FOOTER ------------------------------ */