.monthcalendar-main-container{
    background-color:white;
    width:800;
    height: 600;
    padding-top: 10px;
    margin:0 25px 10px 25px;
  
}

.monthcalendar-table-class{
    width: 100%;
    background-color: #d8d4d4;
    border-spacing: 1px;
    border-color: black;
    min-width: 820px;
    border-collapse: separate;
    


}

.monthcalendar-table-container{
    overflow: auto;
    width:100%;
}
.monthcalendar-tablehead-class{
    background-color:white !important;
   
}







.monthcalendar-container-evenimente{
    list-style-type: disc;
   padding-left: 20px;
   padding-right:3px;
   word-break:break-word
 

}

.monthcalendar-eveniment{
    color:black;
    margin-bottom:5px;
}

.monthcalendar-title{
    color:#ea7ab7;
    margin: 0 10px 0 10px;
}
.monthcalendar-header{
    text-align: center;
  
    margin:0 0 20px 0;
    font-size:20px
}

.meditatii-calendar-weekday{
    color:#7a80dc;
}

.meditatii-calendar-weekend{
    color:#ea7ab7;
}

.monthcalendar-table-class td{
   width:10%;
  background-color:white;
  padding:0;
}
.monthcalendar-weekend-label{
    color: rgb(159, 160, 165);
    font-size: 17px;
    margin: 2px 0 2px 2px;
    font-weight: 400
}

.monthcalendar-weekday-label{
    color: black;
    font-size: 17px;
    margin: 2px 0 2px 2px;
    font-weight: 400
}
.monthcalendar-weekend-class{
    background-color:#fbfbfb;
   text-align: left;
    height: 15vh;
    overflow: scroll;
}
.monthcalendar-weekday-class{
    background-color:white;
    text-align: left;
    height: 15vh;
    overflow: scroll;

}